import Navbar from '../components/Navbar'
import Furniture from '../components/Furniture'
import Footer from '../components/Footer'

function Trabajos() {
    return (
        <div>
        <Navbar/>
         <Furniture/>
         <Footer/>
         </div>
    )
}

export default Trabajos