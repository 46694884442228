import Navbar from "../components/Navbar";
import Main from "../components/Main"
import Footer from "../components/Footer"

const Home = () => {
    return (
        <div>
        <Navbar/>
        <Main/>
        <Footer/>
        </div>
    )
}

export default Home;